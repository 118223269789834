import React from 'react';
import PageHeader from '../PageHeader';
import styles from './products.module.scss';

const Products = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';

  return (
    <main>
      <PageHeader pageCategory="About" pageName="Products" headerImage={background} />
      <div className={styles.textContainer}>
        <h2 className={styles.header}>Emma&apos;s Worry Clouds</h2>
        <img
          className={styles.image}
          src="https://res.cloudinary.com/dewd4pral/image/upload/v1499193614/emmasWorryClouds_zmzmz8.jpg"
          alt="Emma's Worry Clouds Cover"
        />

        <p className={styles.copy}>
          When Emma was 7 years old, she seemed to be catching a bug every Monday morning. She’d
          complain of stomachaches and didn’t want to go to school. Initially, Mom kept her home
          from school, believing Emma was sick. Usually, about an hour later, Mom would find Emma
          giggling and happily playing with her 4-year-old sister. She appeared to have been healed
          miraculously.
          <br />
          <br />
          Mom often wondered if Emma had been truthful and threatened to take her to school. Emma’s
          stomachaches would immediately return. This situation happened often enough to lead Mom to
          consult a professional. Mom discovered that Emma’s challenge wasn’t integrity or a stomach
          problem. Emma was experiencing too many worries.
          <br />
          <br />
          Emma’s story probably sounds familiar if you have children with worries. Do you know what
          is happening and why?
          <br />
          <br />
          Reading <em>Emma’s Worry Clouds</em> will help you and your child learn how to manage the
          worries that sometimes are manifested with aches, such as stomachaches. It was written to
          help children normalize their worries and also gain skills to handle them.
        </p>
      </div>

      <div style={{ marginTop: -70 }} className={styles.textContainer}>
        <p className={styles.buyNowLink}>
          <a
            href="https://www.amazon.com/Emmas-Worry-Clouds-Annabella-Hagen/dp/0997321008/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy now on Amazon
          </a>
        </p>
      </div>

      <div style={{ marginTop: -50 }} className={styles.textContainer}>
        <h2 className={styles.header}>Las Nubes Intranquilas de Emma (Spanish Edition)</h2>
        <img
          className={styles.image}
          src="https://res.cloudinary.com/dewd4pral/image/upload/v1499481315/emmasWorryCloudsSpanish_yqv4nh.jpg"
          alt="Emma's Worry Clouds Cover Spanish Edition"
        />

        <p className={styles.copy}>
          A Emma le gusta hacer muchas cosas, pero a veces tiene miedo de otras. Ella quiere ser
          valiente y una noche un torbellino se la lleva a lugares desconocidos y fascinantes. Allí
          se hace de nuevos amigos que le enseñan qué hacer cuando las “nubes intranquilas” se
          aparezcan.
          <br />
          <br />
          Las preocupaciones de Emma están representadas con nubes siendo que el clima cambia todo
          el tiempo. Los niños pueden aprender que los sentimientos, incluyendo las preocupaciones
          van y vienen como las nubes en el cielo en cualquier clase de clima.
          <br />
          <br />
          Este libro le enseña a los niños cómo pueden aprender a respirar con su pancita lentamente
          y así sentirse mejor cuando las nubes intranquilas pasen en sus vidas.
        </p>
      </div>

      <div style={{ marginTop: -60 }} className={styles.textContainer}>
        <p className={styles.buyNowLink}>
          <a
            href="https://www.amazon.com/Las-Nubes-Intranquilas-Emma-Spanish/dp/0997321016/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy now on Amazon (Spanish Edition)
          </a>
        </p>
      </div>

      <div style={{ marginTop: -50 }} className={styles.textContainer}>
        <h2 className={styles.header}>Nico the Worried Caterpillar</h2>
        <img
          className={styles.image}
          src="https://res.cloudinary.com/dewd4pral/image/upload/v1555468322/nico.jpg"
          alt="Nico the Worried Caterpillar"
        />

        <p className={styles.copy}>
          Nico loved doing the same things every day. He ate yummy leaves, went to school, and
          played with this friends. Then he ate more yummy leaves, read books, brushed his teeth,
          and went to bed.
          <br />
          <br />
          When Nico finds out that he will become a butterfly he becomes angry, as he doesn’t like
          to try new things. His teacher at caterpillar school prepares him for the changes. When he
          finds himself in his cocoon, he feels scared and sad.
          <br />
          <br />
          Come along with Nico, as he rediscovers what his teacher taught him, and how he learns to
          be curious and try new things!
        </p>
      </div>

      <div style={{ marginTop: -60 }} className={styles.textContainer}>
        <p className={styles.buyNowLink}>
          <a
            href="https://www.amazon.com/Nico-Worried-Caterpillar-Annabella-Hagen/dp/0997321024/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy now on Amazon
          </a>
        </p>
      </div>
    </main>
  );
};

export default Products;
